import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';

import Form from 'react-bootstrap/Form';
import FormCheck from 'react-bootstrap/FormCheck';
import Button from 'react-bootstrap/Button';
import GDPR from '../../data/cukolada-gdpr.pdf';

const encode = (data) => {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
};

// eslint-disable-next-line react/prefer-stateless-function
const ContactForm = () => {
  const [fields, setFields] = useState({
    bezlaktozova: 'nie',
    bezlepkova: 'nie',
    vegan: 'nie',
  });
  const [validated, setValidated] = useState(false);

  const switchLabel = (
    <p>
      Súhlasím so spracovaním osobných údajov a prečítal som si{' '}
      <a style={{ color: '#532A12' }} href={GDPR} target="_blank" rel="noopener noreferrer">
        Zásady spracovania osobných údajov.
      </a>
    </p>
  );

  const handleChange = (e) => {
    setFields({ ...fields, [e.target.name]: e.target.value });
  };

  const handleCheckChange = (e) => {
    setFields({ ...fields, [e.target.name]: e.target.checked ? 'ano' : 'nie' });
  };
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity()) {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        // eslint-disable-next-line react/destructuring-assignment
        body: encode({ 'form-name': 'contact', ...fields }),
      })
        // eslint-disable-next-line no-undef
        .then(() => alert('Odoslané!'))
        // eslint-disable-next-line no-undef
        .catch((error) => alert(error));
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }

    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
  };

  return (
    <Form
      name="contact"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      noValidate
      validated={validated}
      onSubmit={handleSubmit}>
      <input type="hidden" name="form-name" value="contact"/>
      <input type="hidden" name="bot-field"/>
      <Form.Row className="form-component justify-content-end align-content-center pb-5">
        <Col md={6}>
          <h2 className="form-heading">Pošlite správu</h2>
          <Form.Group controlId="validationCustom01">
            <Form.Control
              onChange={handleChange}
              value={fields.name}
              className="credentials-input"
              required
              name="name"
              type="text"
              placeholder="Meno a priezvisko"
            />
            <Form.Control.Feedback type="invalid">Nesmie byť prázdne</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="validationCustom02">
            <Form.Control
              onChange={handleChange}
              className="credentials-input"
              required
              value={fields.email}
              name="email"
              type="email"
              placeholder="Emailová adresa"
            />
            <Form.Control.Feedback type="invalid">Nesprávny e-mail.</Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="validationCustom04">
            <Form.Control
              onChange={handleChange}
              className="message-input"
              type="text"
              placeholder="Obsah správy"
              required
              value={fields.message}
              name="message"
            />
            <Form.Control.Feedback type="invalid">Nesmie byť prázdne</Form.Control.Feedback>
          </Form.Group>
          <div className="form-checkboxes">
            <Form.Group controlId="formBasicCheckbox01">
              <Form.Check name="vegan" onChange={handleCheckChange} className="checkboxInput" type="checkbox"
                          label="Vegan"/>
            </Form.Group>
            <Form.Group controlId="formBasicCheckbox02">
              <Form.Check name="bezlaktozova" onChange={handleCheckChange} className="checkboxInput" type="checkbox"
                          label="Bezlaktózové"/>
            </Form.Group>
            <Form.Group controlId="formBasicCheckbox03">
              <Form.Check name="bezlepkova" onChange={handleCheckChange} className="checkboxInput" type="checkbox"
                          label="Bezlepkové"/>
            </Form.Group>
          </div>
          <i>Ďalšíe prípadné obmedzenia (alergie, intolerancie...) napíšte do správy.</i>
          <Form.Group>
            <FormCheck
              onChange={handleChange}
              name="gdpr"
              type="switch"
              id="gdprSwitch"
              checked={fields.gdpr}
              required
              label={switchLabel}
              feedback="Musíte súhlasiť"
            />
          </Form.Group>
          <Button className="submit-button" value="Submit" type="submit">
            Odoslať
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );
};

export default ContactForm;
