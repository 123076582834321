import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Col, Container, Row } from 'react-bootstrap';
import Carousel from 'react-images-maintained';
import Layout from '../components/layout';
import ContactForm from '../components/form/ContactForm';
import SEO from '../components/seo';

const ProductsPage = ({ pageContext: { node } }) => (
  <Layout>
    <SEO title={node.title} description="Momentálne dostuplné koláče" />
    <section className={`menu-bg-${node.tag}`}>
      <Container>
        <Row className="pb-5">
          <Col md={12} className="products-line">
            <h1 className="pt-5">{node.title}</h1>
          </Col>
          <Col md={6} className="pt-4">
            <ReactMarkdown source={node.description} />
          </Col>
          <Col md={6} className="pt-4">
            <Carousel views={node.gallery} />
          </Col>
        </Row>
        <ContactForm />
      </Container>
    </section>
  </Layout>
);

export default ProductsPage;
